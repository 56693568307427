import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Table, Row, Spinner, Col } from 'reactstrap'
import { JudgeInquiryList } from '../../../store/actions'
import { getDateWithFormat } from '../../../helpers/date_helper'
import moment from 'moment'
import { Collapse } from '@material-ui/core'
import Pagination from '../Pagination/Pagination'

const JudgeInquiry = () => {
  const [open, setOpen] = useState(null)
  const dispatch = useDispatch()
  const { page, perPage } = useSelector((state) => state.pagination)
  const { InquiryList, loading } = useSelector((state) => state.Inquiry)
  useEffect(() => {
    let obj = {
      _page: 1,
      _limit: perPage,
      _sort: 'sscu.created_on',
      _order: 'DESC',
    }
    dispatch(JudgeInquiryList(obj))
  }, [perPage])
  const handleClick = (data) => {
    if (data === open) {
      setOpen(null)
    } else {
      setOpen(data)
    }
  }

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let obj = {
      _page: finalPage,
      _limit: perPage,
      _sort: 'sscu.created_on',
      _order: 'DESC',
    }
    dispatch(JudgeInquiryList(obj))
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6'>
        <Row>
          <div className='col-md-8 msg-box'>
            {/* <p className='text-red'>
              Note: All entries will be deleted in 30 days.
            </p> */}
          </div>
          <div className='col-md-4 text-end'>
            <Link to='/easyfides/profile/judge' className='' title='Back'>
              <Button> BACK </Button>
            </Link>
          </div>
        </Row>
        <div className='col-md-12 mt-3'>
          <Table responsive hover1 className='table cust-tab table'>
            <thead className='table-color'>
              <tr>
                <th>No.</th>
                <th>Judge Name</th>
                <th>Service</th>
                <th>Breed</th>
                <th>Miles</th>
                <th>Inquiry From</th>
                <th>Date & Time</th>
                <th>More Info</th>
              </tr>
            </thead>
            <tbody>
              {!loading && Array.isArray(InquiryList) && InquiryList.length ? (
                InquiryList.map((inquiry, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{(page - 1) * perPage + (index + 1)}</td>
                        <td>{inquiry?.service_display_name || '-'}</td>

                        <td>
                          <>
                            {inquiry?.service_serve_as_judge ? 'Judge' : null}
                            {'  '}{' '}
                            {inquiry?.service_serve_as_judge &&
                            inquiry?.service_serve_as_registrar
                              ? ' & '
                              : null}
                            {inquiry?.service_serve_as_registrar
                              ? 'Registrar'
                              : null}
                          </>
                        </td>
                        <td>
                          {inquiry?.service_rabbit_judge ||
                          inquiry?.service_cavy_judge ? (
                            <>
                              {inquiry?.service_rabbit_judge ? 'Rabbit' : null}
                              {'  '}{' '}
                              {inquiry?.service_rabbit_judge &&
                              inquiry?.service_cavy_judge
                                ? ' & '
                                : null}
                              {inquiry?.service_cavy_judge ? 'Cavy' : null}
                            </>
                          ) : (
                            '-'
                          )}
                        </td>

                        <td>{inquiry?.travel_radius_title || '-'}</td>
                        <td>
                          <strong>Name:</strong>
                          {inquiry?.req_user_name || '-'} <br />{' '}
                          <strong>Email: </strong>
                          {inquiry?.req_user_email || '-'}
                        </td>
                        <td>
                          {moment(getDateWithFormat(inquiry.created_on)).format(
                            'MMM Do YYYY LT'
                          )}
                        </td>
                        <td>
                          <i
                            className='fa fa-arrow-circle-down down-arrow'
                            onClick={() =>
                              handleClick(inquiry?.sales_service_contact_us_id)
                            }
                          ></i>
                        </td>
                      </tr>
                      {inquiry?.sales_service_contact_us_id === open ? (
                        <tr>
                          <td colSpan={9}>
                            <Collapse
                              in={
                                inquiry?.sales_service_contact_us_id === open
                                  ? true
                                  : false
                              }
                              timeout='auto'
                              unmountOnExit
                            >
                              <div className='disc-box text-left w-break'>
                                {inquiry?.description || ''}
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )
                })
              ) : loading ? (
                <tr className='text-center'>
                  <td colSpan={10}>
                    <Spinner color='primary' />
                  </td>
                </tr>
              ) : (
                <tr className='text-center'>
                  <td colSpan={10}>No judge inquiry found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default JudgeInquiry
