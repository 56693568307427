import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table, Row, Col, Spinner } from 'reactstrap'
import Pagination from '../Pagination/Pagination'
import {
  EquipmentDelete,
  listingEquipment,
  pageChange,
} from '../../../store/actions'
import { UrlEncodeHook } from '../../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import Logo from '../../../assets/images/logo.png'
import { get } from '../../../helpers/api_helper'
import _ from 'lodash'
const EquipmentList = () => {
  const { urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()
  const { page, perPage } = useSelector((state) => state.pagination)
  const [reload, setReload] = useState(false)
  const { EquipmentList, loading } = useSelector((state) => state.Equipment)
  //
  useEffect(() => {
    let params = {
      _limit: perPage,
      _page: 1,
      _sort: 'created_on',
      _order: 'DESC',
      product_from: 'equipment',
    }
    dispatch(listingEquipment(params))
  }, [reload])

  //
  const onDelete = async (ID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let obj = {
          ID,
          setReload,
          reload,
        }
        let finalPage = page
        if (Array.isArray(EquipmentList) && EquipmentList.length === 1) {
          finalPage -= 1
        }
        if (finalPage <= 0) {
          finalPage = 1
        }
        if (page === finalPage) {
          obj[`setReload`] = setReload
          obj[`reload`] = reload
        }

        dispatch(pageChange(finalPage))

        dispatch(EquipmentDelete(obj))
      }
    })
  }

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let params = {
      _limit: perPage,
      _page: finalPage,
      _sort: 'created_on',
      _order: 'DESC',
    }
    dispatch(listingEquipment(params))
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6'>
        <Row>
          <div className='col-md-8 text-left msg-box'>
            {/* <p className='text-red'>
              Note: All entries will be deleted in 30 days.
            </p> */}
          </div>
          <div className='col-md-4 text-end'>
            <Link
              to='/easyfides/profile/equipments/inquiry'
              className='me-2'
              title='Inquiry'
            >
              <Button color='warning'>INQUIRY </Button>
            </Link>
            <Link
              to='/easyfides/profile/equipments/create'
              className=''
              title='Create'
            >
              <Button> CREATE </Button>
            </Link>
          </div>
        </Row>
        <div className='col-md-12 mt-3'>
          <Table responsive hover1 className='table cust-tab table'>
            <thead className='table-color'>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Category Name</th>
                <th>Sub Category Name</th>
                <th>Product Name</th>
                <th>Image</th>
                <th>Amount </th>
                <th className='text-center'>Action </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
              Array.isArray(EquipmentList) &&
              EquipmentList.length ? (
                EquipmentList.map((equipment, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * perPage + (index + 1)}</td>
                      <td className='text-capitalize'>
                        {equipment?.equipment_product_type}
                      </td>
                      <td> {equipment?.category_name}</td>
                      <td> {equipment?.sub_category_name}</td>
                      <td> {equipment?.product_name}</td>
                      <td>
                        {' '}
                        <ImageComponent ID={equipment?.equipment_product_id} />
                      </td>
                      <td>
                        {equipment?.amount && parseInt(equipment?.amount) !== 0
                          ? `$${equipment?.amount}`
                          : parseInt(equipment?.amount) === 0
                          ? '$0.00'
                          : ''}
                      </td>
                      <td className='text-center'>
                        <Link
                          to={`/easyfides/profile/equipments/equipment_image/${urlEncode(
                            equipment?.equipment_product_id
                          )}`}
                          className='me-2'
                          title='Add Image'
                        >
                          <Button className='img-btn-add mb-2'>
                            {' '}
                            ADD IMAGE{' '}
                          </Button>
                        </Link>

                        <Link
                          to={`/easyfides/profile/equipments/update/${urlEncode(
                            equipment?.equipment_product_id
                          )}`}
                        >
                          <Button
                            size='sm'
                            color='warning'
                            className='me-2 mb-2'
                            title='Update'
                          >
                            <i className='fas fa-pen'></i>
                          </Button>
                        </Link>

                        <Button
                          className='mb-2'
                          size='sm'
                          color='danger'
                          title='Delete'
                          onClick={() =>
                            onDelete(equipment?.equipment_product_id)
                          }
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              ) : loading ? (
                <tr className='text-center'>
                  <td colSpan={10}>
                    <Spinner color='primary' />
                  </td>
                </tr>
              ) : (
                <tr className='text-center'>
                  <td colSpan={10}>No equipment & services listing found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Row>
            <Col lg={12} className='easy-page'>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default EquipmentList

//
const ImageComponent = ({ ID }) => {
  const [breedData, setbreedData] = useState({})

  useEffect(() => {
    get(
      `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${ID}&doc_type=img`
    ).then((res) => {
      let finalData = res.data
      finalData = _.first(_.orderBy(finalData, 'display_order'))
      setbreedData(finalData)
    })
  }, [ID])

  return breedData?.document_file ? (
    <img
      src={breedData?.document_file}
      alt={breedData?.document_title || ''}
      style={{ width: '100px' }}
      className='img-thumbnail rounded'
      title={breedData?.document_title || ''}
    />
  ) : (
    <img
      src={Logo}
      alt='logo'
      style={{ width: '100px' }}
      className='img-thumbnail rounded'
      title={'logo'}
    />
  )
}
