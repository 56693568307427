import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Row, Button, Spinner, Col } from 'reactstrap'
import { getDateWithFormat } from '../../../helpers/date_helper'
import moment from 'moment'
import { Collapse } from '@material-ui/core'
import Pagination from '../Pagination/Pagination'
import { EquipmentInquiryList } from '../../../store/actions'

const EquipmentInquiry = () => {
  const [open, setOpen] = useState(null)
  const dispatch = useDispatch()
  const { page, perPage } = useSelector((state) => state.pagination)
  const { InquiryList, loading } = useSelector((state) => state.Inquiry)
  useEffect(() => {
    let obj = {
      _page: 1,
      _limit: perPage,
      product_from: 'equipment',
      equipment_from: 'nonvendor',
      _sort: 'eqpcu.created_on',
      _order: 'DESC',
    }
    dispatch(EquipmentInquiryList(obj))
  }, [])
  const handleClick = (data) => {
    if (data === open) {
      setOpen(null)
    } else {
      setOpen(data)
    }
  }

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let obj = {
      _page: finalPage,
      _limit: perPage,
      product_from: 'equipment',
      equipment_from: 'nonvendor',
      _sort: 'eqpcu.created_on',
      _order: 'DESC',
    }
    dispatch(EquipmentInquiryList(obj))
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6'>
        <Row>
          <div className='col-md-8 msg-box'>
            {/* <p className='text-red'>
              Note: All entries will be deleted in 30 days.
            </p> */}
          </div>
          <div className='col-md-4 text-end'>
            <Link to='/easyfides/profile/equipments' className='' title='Back'>
              <Button> BACK </Button>
            </Link>
          </div>
        </Row>
        <div className='col-md-12 mt-3'>
          <Table responsive hover1 className='table cust-tab table'>
            <thead className='table-color'>
              <tr>
                <th>No.</th>
                <th>Type</th>
                <th>Category Name</th>
                <th>Sub Category Name</th>
                <th>Product Name</th>
                <th>Amount</th>
                <th>Inquiry From</th>
                <th>Date & Time</th>
                <th>More Info</th>
              </tr>
            </thead>
            <tbody>
              {!loading && Array.isArray(InquiryList) && InquiryList.length ? (
                InquiryList.map((inquiry, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{(page - 1) * perPage + (index + 1)}</td>
                        <td className='text-capitalize'>
                          {inquiry?.equipment_product_type || '-'}
                        </td>
                        <td>{inquiry?.sponsor_category_name || '-'}</td>
                        <td>{inquiry?.sponsor_sub_category_name || '-'}</td>
                        <td>{inquiry?.equipment_product_name || '-'}</td>
                        <td>
                          {inquiry?.equipment_amount &&
                          parseInt(inquiry?.equipment_amount) !== 0
                            ? `$${inquiry?.equipment_amount}`
                            : parseInt(inquiry?.equipment_amount) === 0
                            ? '$0.00'
                            : ''}
                        </td>
                        <td>
                          <strong>Name:</strong>
                          {inquiry?.req_user_name || '-'} <br />{' '}
                          <strong>Email: </strong>
                          {inquiry?.req_user_email || '-'}
                        </td>
                        <td>
                          {moment(getDateWithFormat(inquiry.created_on)).format(
                            'MMM Do YYYY LT'
                          )}
                        </td>
                        <td>
                          <i
                            className='fa fa-arrow-circle-down down-arrow'
                            onClick={() =>
                              handleClick(
                                inquiry?.equipment_product_contact_us_id
                              )
                            }
                          ></i>
                        </td>
                      </tr>
                      {inquiry?.equipment_product_contact_us_id === open ? (
                        <tr>
                          <td colSpan={9}>
                            <Collapse
                              in={
                                inquiry?.equipment_product_contact_us_id ===
                                open
                                  ? true
                                  : false
                              }
                              timeout='auto'
                              unmountOnExit
                            >
                              <div className='disc-box text-left w-break'>
                                {inquiry?.message || ''}
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )
                })
              ) : loading ? (
                <tr className='text-center'>
                  <td colSpan={10}>
                    <Spinner color='primary' />
                  </td>
                </tr>
              ) : (
                <tr className='text-center'>
                  <td colSpan={10}>No equipment inquiry found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default EquipmentInquiry
