import { SUB_CATEGORY_LISTING, SUB_CATEGORY_DETAIL } from './actionType'

export const subCategoryList = (data) => {
  return {
    type: SUB_CATEGORY_LISTING,
    payload: data,
  }
}

export const subCategoryDetail = (data) => {
  return {
    type: SUB_CATEGORY_DETAIL,
    payload: data,
  }
}
