import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Modal, Row } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { premierUserResetCardDetail, premierUsers } from '../../store/actions'

import { UrlEncodeHook } from '../../hooks'
import _ from 'lodash'

const PremiumMembership = () => {
  const [showModal, setShowModal] = useState(false)
  const { urlDecode, urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()
  const { user, premierUser } = useSelector((state) => state.Login)
  const userData = useSelector((state) => state.Login)
  // const navigate =
  useEffect(() => {
    dispatch(premierUserResetCardDetail())
    if (user?.id) {
      let data = {
        user_id: user.id,
      }
      dispatch(premierUsers(data))
    }
  }, [user?.id])

  // const user = JSON.parse(localStorage.getItem('authUser'))
  // if (premierUser?.premier_exhibitor_id) {
  //   return <Redirect to='/' />
  // }

  return (
    <React.Fragment>
      <div className='page-content mb-5'>
        <Container fluid className='fix-container'>
          <Row>
            <Col md='12' className='mt-5 mb-5'>
              <Row className='justify-content-center'>
                <Col sm='6' xs='6' md='4' lg='3'>
                  <Button
                    color='primary'
                    block
                    className='w-100'
                    onClick={() => setShowModal(true)}
                  >
                    Basic “FREE” Easy2Show Profiles Detail
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md='5' className='text-left mt-5 mb-5'>
              <h2 className='top-title text-left mb-2'>
                <span className='text-cap'>Get Started Today</span>
              </h2>
            </Col>
            <Col md='7' className='text-right mt-5 mb-5 resp-spac-none'>
              <h4 className='top-title text-right mb-2 mt-2'>
                <a
                  href='https://www.youtube.com/watch?v=GQxMuWiBba8'
                  target='_blank'
                  className='text-cap link-color'
                  rel='noreferrer'
                >
                  Easy2Show Pedigree Software Overview Video Online
                </a>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6} sm={12} className='box-flex'>
              <div className='pricingTable pre-exh'>
                <div className='outer'>
                  <div className='pricingTable-header'>
                    <h3 className='heading'>PREMIER EXHIBITOR</h3>
                  </div>
                  <div className='inner'>
                    <div className='box'>
                      <div className='pricing-content text-left'>
                        <div className='cont-color'>
                          <ul className='mb-2'>
                            <li>View Complete Show Reports *</li>
                            <li>View "Live" Class placements *</li>
                          </ul>
                        </div>
                        <ul className='mb-2'>
                          <li>View Upcoming Events</li>
                          <li>View Entry Count By Breed</li>
                          <li>Online Entry Portal</li>
                          <li>Easy Entry</li>
                          <li>Easyfieds - unlimited access to post</li>
                        </ul>
                      </div>
                      <div className='pricingTable-footer-price'>
                        <Row>
                          <Col md={12} className='dis-price'>
                            {' '}
                            {/* <div className="deceased"></div> */}
                            <strong className='price-f'>
                              $24 *fees/year
                            </strong>{' '}
                            <br /> <p></p>
                            <small>
                              *Plus 7% State Sales Tax & 3% Processing Fees
                            </small>
                          </Col>
                          {/* <Col md={7} className="dis-price">
                            <p className="mt-0 mb-1 price-hei pre-tab-space">
                              <strong>
                                Convention Special*
                                <br />
                                <span className="price-f">$20/year</span>
                              </strong>{' '}
                              <br />{' '}
                              <span className="res-until">
                                Until Nov. 6th 2024
                              </span>
                            </p>
                          </Col> */}
                          <Col
                            md={12}
                            className='dis-price only-shows text-center'
                          >
                            <small>*Only shows that use Easy2show</small>
                          </Col>
                        </Row>
                      </div>
                      <div className='pricingTable-notice'>
                        {/* <a
                          color='primary'
                          class='btn btn-primary mt-2 btn-pre-exh'
                          title='Purchase'
                          href='/'
                        >
                          PURCHASE
                        </a> */}
                        <p>Prices subject to change without notice.</p>
                      </div>

                      <div className='card-footer text-center pos-relative'>
                        <div className='fixex-btn'>
                          {_.size(userData.planListObj['premier']) ? (
                            <Button
                              color='primary'
                              className='btn btn-primary mt-3 mb-2 btn-pre-exh'
                              title='Purchase'
                              disabled
                            >
                              PURCHASE
                            </Button>
                          ) : (
                            <Link
                              to={
                                user?.id
                                  ? `/premier-membership/checkout?p=${urlEncode(
                                      1
                                    )}`
                                  : '/login'
                              }
                              color='primary'
                              block
                              className='btn btn-primary mt-3 mb-2 btn-pre-exh'
                              title='Purchase'
                            >
                              PURCHASE
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className='box-flex'>
              <div className='pricingTable herd-mgt'>
                {/* <div class='ribbon-ex'>COMING SOON</div> */}
                <div className='outer'>
                  <div className='pricingTable-header'>
                    <h3 className='heading'>HERD MANAGEMENT</h3>
                  </div>
                  <div className='inner'>
                    <div className='box'>
                      <div className='pricing-content hert-mgt-list'>
                        <ul>
                          <li>
                            <br />
                            <h3 className='pedi-mgt-head'>
                              HERD MANAGEMENT <br /> and <br /> PEDIGREE
                              MANAGEMENT
                            </h3>
                          </li>
                          <br />
                          <br />
                          <li>More information to come.</li>
                          {/* <li>Currently Available with these features :</li>
                          <li>
                            <small>
                              <span className="text-red w-break1 res-text">
                                Rabbits & Cavies included 
                                <br />
                                New
                                Breeds/Varieties/Crossbred as well as Imports{' '}
                                <br />
                                3 “users” at the same address <br />
                              </span>{' '}
                              and more features to come
                            </small>
                          </li> */}

                          <br />
                          <br />
                          <li>
                            <img
                              className=''
                              src={'/assets/img/under-construction.png'}
                              title=''
                              alt=''
                            />
                          </li>
                          <br />
                          <br />
                          <li>Please check back for updates</li>
                          {/* <li>
                            <small>
                              Pre buy now to get our special price and all
                              additional features to launch in the next several
                              months
                            </small>
                          </li> */}
                        </ul>
                      </div>

                      {/* <div className="pricingTable-footer-price">
                        <Row>
                          <Col md={12} className="dis-price">
                            <p className="mt-0 price-f price-hei">
                              <strong>$100/year</strong> <br />{' '}
                            </p>
                            <small>
                              *Plus 7% State Sales Tax & 3% Processing Fees
                            </small>{' '}
                          </Col>
                        </Row>
                      </div> */}

                      {/* <div className="pricingTable-notice">
                        <p className="herd-mgt-msg">
                          The ability to purchase Herd Management will be
                          available on Nov. 11th 2024
                        </p>
                        <a color="primary" className="btn btn-primary mt-4 btn-herd-mgt" title="Purchase" href="/">PURCHASE</a>
                        <p>Prices subject to change without notice.</p>
                      </div> */}

                      {/* <div class="card-footer text-center pos-relative">
                        <div className="fixex-btn">
                          {_.size(userData.planListObj['herd']) ? (
                            <Button
                              color="primary"
                              className="btn btn-primary mt-3 mb-2 btn-herd-mgt"
                              title="Purchase"
                              disabled
                            >
                              PURCHASE
                            </Button>
                          ) : (
                            <Link
                              to={
                                user?.id
                                  ? `/premier-membership/checkout?p=${urlEncode(
                                      2
                                    )}`
                                  : '/login'
                              }
                              color="primary"
                              className="btn btn-primary mt-3 mb-2 btn-herd-mgt"
                              title="Purchase"
                            >
                              PURCHASE
                            </Link>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className='box-flex'>
              <div className='pricingTable pre_herd'>
                {/* <div class='ribbon-ex'>COMING SOON</div> */}
                <div className='ribbon-ex'>EXCLUSIVE</div>
                <div className='outer'>
                  <div className='pricingTable-header'>
                    <h3 className='heading'>
                      PREMIER EXHIBITOR <br /> <small>and</small> <br />{' '}
                      PEDIGREE MANAGEMENT
                    </h3>
                  </div>
                  <div className='inner'>
                    <div className='box'>
                      <div className='pricing-content hert-mgt-list'>
                        <ul className='mb-0'>
                          <li>
                            For a limited time purchase{' '}
                            <h3 className='mt-3'>
                              {' '}
                              PREMIER EXHIBITOR <br /> <small>
                                and
                              </small> <br /> PEDIGREE MANAGEMENT
                            </h3>{' '}
                            <small className='text-yellow-basic'>
                              {' '}
                              "Basic" Pedigree Management available now,
                              purchase at this price and receive updates as they
                              launch.
                            </small>{' '}
                            {/* <small>
                              {' '}
                              For this special price and <br /> receive all
                              future features for this special price
                            </small>{' '} */}
                          </li>
                        </ul>
                      </div>

                      <div className='pricingTable-footer-price'>
                        <Row>
                          <Col md={4} className='dis-price'>
                            <img
                              className=''
                              src={'/assets/img/horn.png'}
                              title=''
                              alt=''
                            />
                          </Col>
                          <Col md={8} className='dis-price'>
                            <p className='mt-4 w-break1 price-hei'>
                              <strong>
                                <span className='price-f'>$75 *fees/year</span>
                              </strong>
                            </p>
                          </Col>
                          <Col md={12} className='dis-price'>
                            <small>
                              *Plus 7% State Sales Tax & 3% Processing Fees
                            </small>
                          </Col>
                        </Row>
                      </div>
                      <div className='pricingTable-notice'>
                        {/* <p className="pre-herd-msg">
                          Premier Exhibitor is available now,{' '}
                          <u>Herd Management</u> will be available on Nov. 11th
                          2024{' '}
                        </p> */}
                        {/* <a color="primary" className="btn btn-primary mt-2 btn-pre-herd-mgt" title="Purchase" href="/">PURCHASE</a> */}
                        <p>Prices subject to change without notice.</p>
                      </div>

                      <div className='card-footer text-center pos-relative'>
                        <div className='fixex-btn'>
                          {_.size(userData.planListObj['combined']) ? (
                            <Button
                              color='primary'
                              className='btn btn-primary mt-3 mb-2 btn-pre-herd-mgt'
                              title='Purchase'
                              disabled
                            >
                              PURCHASE
                            </Button>
                          ) : (
                            <Link
                              to={
                                user?.id
                                  ? `/premier-membership/checkout?p=${urlEncode(
                                      3
                                    )}`
                                  : '/login'
                              }
                              color='primary'
                              block
                              className='btn btn-primary mt-3 mb-2 btn-pre-herd-mgt'
                              title='Purchase'
                            >
                              PURCHASE
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            {!user?.id ? (
              <Col md='12' className='text-dark fw-bolder fs-41 mt-4 pre-text'>
                You need to have a basic profile created (should have a login
                account setup to upgrade to premier) if you do not have a basic
                account please{' '}
                <Link to='/register' className='text-decoration-underline'>
                  click here
                </Link>{' '}
                to register
                <br />
                <small className='res-text'>
                  Only results and reports for shows that use Easy2Show show
                  software are available to view.
                </small>
              </Col>
            ) : (
              <Col md='12' className='text-dark fw-bolder fs-41 mt-4 pre-text'>
                <small className='res-text mt-4'>
                  Only results and reports for shows that use Easy2Show show
                  software are available to view.
                </small>
              </Col>
            )}
          </Row>
          <Modal centered={true} isOpen={showModal} size='lg'>
            <div className='modal-body' style={{ background: '#fff' }}>
              <Row>
                <Col lg={12} md={12} sm={12} className='box-flex'>
                  <div className='pricingTable mb-0 pre-exh '>
                    <div className='outer'>
                      <div className='pricingTable-header bg-white'>
                        <h3 className='heading text-dark'>
                          Basic “FREE” Easy2Show Profiles
                        </h3>
                      </div>
                      <div className='inner'>
                        <p className='text-dark mt-4 mb-4'>
                          Anyone can set up a FREE Easy2Show profile. Click
                          “Sign Up” on the login screen and follow the
                          instructions to set up your profile.
                        </p>

                        <h2 className='mb-4'>FREE Profiles include:</h2>
                        <div className='box'>
                          <div className='pricing-content text-left'>
                            <ul className='mb-2'>
                              <li>View upcoming Events</li>
                              <li>View overall entry counts by event</li>
                              <li>Enter any show using Easy2Show</li>
                              <li>
                                Make changes to your entry prior to the show
                                deadline using the “your participation” tab
                              </li>
                              <li>
                                View and print your show reports and legs from
                                your profile for up to 1- year from the show
                                date.
                              </li>
                              <li>
                                All show reports and legs are emailed by the
                                show secretary directly to your email account
                              </li>
                              <li>
                                View Easyfieds (our online classified section).
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className='modal-footer justify-content-center'
              style={{ background: '#fff' }}
            >
              <Button onClick={() => setShowModal(false)}>CLOSE</Button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PremiumMembership
