import { ALL_LISTING_EQUIPMENT_INQUIRY, ALL_LISTING_INQUIRY_SUCCESS, SET_LOADING } from './actionType'

//

const INIT_STATE = {
  InquiryList: [],
  loading: true,
  error: {},
}

const InquiryReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ALL_LISTING_EQUIPMENT_INQUIRY:
      return {
        ...state,
        InquiryList: [],
        loading: true,
      }
    case ALL_LISTING_INQUIRY_SUCCESS:
      return {
        ...state,
        InquiryList: payload,
        loading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}

export default InquiryReducer
