import moment from 'moment'
export function getDate(date) {
  let date1 = null
  if (date) {
    date1 = date.replace('T', ' ')
    date1 = date1.replace('Z', '')
    date1 = moment(date1).format('MMMM Do YYYY')
  }

  return date1

  // return moment.utc(date).utcOffset('-06:00').format('MMMM Do YYYY')
}
export function getDateWithFormat(date) {
  let date1 = date.replace('T', ' ')
  date1 = date1.replace('Z', '')
  return date1
}
export function getTime(date) {
  let date1 = date.replace('T', ' ')
  date1 = date1.replace('Z', '')
  date1 = moment(date1).format('hh:mm A')
  return date1
}
export function getDbDate(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function getDateWithZone(date) {
  return moment(date).local().format()
}

export function getFormatDate(data) {
  let date1 = null
  date1 = moment(data).format('YYYY-MM-DD')
  return date1
}
export function getFormatDateWithTime(data) {
  let date1 = null;
  date1 = moment(data).set('hours', 4).set('minutes', 30).format('YYYY-MM-DD HH:mm:ss');
  return date1;
}