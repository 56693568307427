import React from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Button, Table, Row } from 'reactstrap';

const Inquiry = () => {
  return (
    <div className="profile-content-area my-6 card card-body mb-10">
      <div className="pb-6">
        <Row>
          <div className="col-md-12 msg-box">
            {/* <p className="text-red">Note: All entries will be deleted in 30 days.</p> */}
          </div>
        </Row>
        <div className="col-md-12 mt-3">
          <Table responsive hover1 className="table cust-tab table">
            <thead className="table-color">
              <tr>
                <th>No.</th>
                <th>Category / Product Name</th>
                <th>Inquiry From</th>
                <th>Description</th>
                <th>Inquiry For</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Rabbits Breed List - American</td>
                <td><strong>Name:</strong> Vaibhav Yewale <br/> <strong>Email: </strong>vyewale@starlab.co.in</td>
                <td>Description Data </td>
                <td>Animal Listing</td>
                <td>Sep 18th 2023 06:34 PM</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Feed / Water</td>
                <td><strong>Name:</strong> Vaibhav Yewale <br/> <strong>Email: </strong>vyewale@starlab.co.in</td>
                <td>Description Data </td>
                <td>Equipment</td>
                <td>Sep 18th 2023 06:34 PM</td>
              </tr>
            </tbody>
          </Table>
          <ReactPaginate
            // {...props}
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            nextLabel='>'
            pageCount={5}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            previousLabel='<'
            renderOnZeroPageCount={null}
            containerClassName='pagination mtb-4'
            pageLinkClassName='page-link page-link'
            pageClassName='page-item'
            activeClassName='active'
            nextLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            // onPageChange={paginationClick}
            forcePage={3 - 1}
        />
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
