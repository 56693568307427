import { put, takeEvery } from 'redux-saga/effects'

import {
  SUB_CATEGORY_DETAIL,
  SUB_CATEGORY_DETAIL_SUCCESS,
  SUB_CATEGORY_LISTING,
  SUB_CATEGORY_LISTING_PAGINATION_DATA,
  SUB_CATEGORY_LISTING_SUCCESS,
} from './actionType'
import { toast } from 'react-toastify'

import { get } from '../../../helpers/api_helper'

function* subCategoryListingSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/category/admin/sponsor-vendor/sub-category/all`,
      {
        params: payload?.params,
      }
    )

    yield put({
      type: SUB_CATEGORY_LISTING_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?.params?._limit) {
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload?.params?._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: SUB_CATEGORY_LISTING_PAGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* subCategoryDetailSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/category/admin/sponsor-vendor/sub-category/one/${payload.ID}`
    )

    yield put({
      type: SUB_CATEGORY_DETAIL_SUCCESS,
      payload: res?.data || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* SubCategorySagas() {
  yield takeEvery(SUB_CATEGORY_LISTING, subCategoryListingSaga)
  yield takeEvery(SUB_CATEGORY_DETAIL, subCategoryDetailSaga)
}

export default SubCategorySagas
