import React, { useState, useEffect } from 'react';
import { getDate, getTime } from '../../../helpers/date_helper';
export const EventMultipleDeadline = (props) => {
  let deadlines = props.deadlines;
  return (
    <React.Fragment>
      <div className="row text-left">
        <hr /> <br />
        <div class="col-lg-12 col-xl-12 mb-4 box-head">
          {/* <strong>This event has multiple deadlines :</strong> */}
          <strong>This event has the following deadline(s) :</strong>
        </div>
        <br />
        {deadlines.map((deadline, index) => {
          return (
            <div className="col-lg-12 col-xl-12  box-head mb-3 mt-2">
              <span className="mult-date">
                {index + 1} . {getDate(deadline.date_time)}{' '}
                {getTime(deadline.date_time)}
                {' CST'}
              </span>{' '}
              - {deadline.description}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
